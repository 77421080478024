import { type ErrorResponse, isRouteErrorResponse, Link, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { ServerCrashIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';

type StatusHandler = (info: {
  error: ErrorResponse;
  params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function GeneralErrorBoundary() {
  const error = useRouteError();
  const { t } = useTranslation();

  captureRemixErrorBoundaryError(error);

  const title = t('error.title');
  const subtitle = t('error.subtitle');
  const backToHome = t('error.backToHomepage');

  if (typeof document !== 'undefined') {
    console.error(error);
  }

  let description = 'Something went wrong while loading this page.';

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      description = "We couldn't find the page you're looking for. Try checking the URL.";
    }

    if (error.status === 401) {
      description = "You aren't authorized to see this page";
    }

    if (error.status === 503) {
      description = 'Looks like our API is down';
    }

    if (error.status === 418) {
      description = "I'm a teapot 🫖!";
    }
  }

  return (
    <div className="flex h-full w-full items-center justify-center bg-white p-5">
      <div className="prose prose-lg max-w-none text-center">
        <div className="inline-flex rounded-full bg-red-100 p-4">
          <ServerCrashIcon className="h-24 w-24 rounded-full bg-red-200 p-4 text-red-600" />
        </div>
        <h2>{title}</h2>
        <p className="mb-1">{subtitle}</p>
        <p className="mt-0">{description}</p>

        <Link className="text-primary underline hover:text-primary-dark hover:no-underline" to="/">
          {backToHome}
        </Link>
      </div>
    </div>
  );
}
